<template>
  <section class='m-auto pt-0 pb-5'>
    <div class='row'>
      <div class='col-10 p-0 m-auto'>
        <div class="timeline-bootstrap" :class="timelineClass">
          <div v-for='(item, index) in items' :key='index' class='timeline-item p-3'>
            <figure class='timeline-badge d-flex flex-column justify-content-center align-items-center' style='gap: 5px'>
              <slot name="badge" :item="item">
                <i class="fa-solid fa-check"></i>
              </slot>
              <slot name="header" :item="item">
                <h5 class="card-title">{{ item.title }}</h5>
              </slot>
            </figure>
            <article class='timeline-body' :style="{ borderColor: badgeColor }" :class="cardClass">
              <div>
                <slot name="content" :item="item">
                  <p class="card-text">{{ item.description }}</p>
                </slot>

                <slot name="footer" :item="item">
                  <small class="text-muted">{{ item.date }}</small>
                </slot>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Timeline',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    badgeColor: {
      type: String,
      default: '#007bff'
    },
    badgeIcon: {
      type: String,
      default: 'bi bi-clock'
    },
    timelineClass: {
      type: String,
      default: ''
    },
    cardClass: {
      type: String,
      default: ''
    },
    lineColor: {
      type: String,
      default: '#dee2e6'
    }
  }
}
</script>

<style scoped>
.timeline-bootstrap {
  padding: 20px 0;
  width: 100%;
}

.timeline-badge {
  width: 27%;
}

.timeline-body {
  width: 70%;
  border-left: 2px solid;
  padding-left: 15px;
}

.timeline-item {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center ;
  background: rgba(128, 128, 128, 0.126);
  margin-bottom: 3px;
  border-radius: 20px;
  height: fit-content;
}
</style>