<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class="pb-5">
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-4">
              <h3 class="card-title font-weight-bold" style="font-size: 25px">Detalles del Mantenimiento</h3>
            </div>
          </template>
          <div class="p-5 row">
            <div class='col-12 col-lg-6 pr-3 pr-lg-4 bg-body mb-3 border'
              style="border-radius: 1rem; box-shadow: 0px 1px 13px 5px rgb(0 0 0 / 0.1)">
              <TimeLine :items="timelineItems" badge-color="#42b883" badge-icon="bi bi-star"
                timeline-class="custom-timeline" line-color="#42b883">
                <!-- Personalizar badge -->
                <template #badge='{ item }'>
                  <i v-if='item.estado == 0' class="fas fa-folder-open" style='font-size: x-large;'></i>
                  <i v-else-if='item.estado == 1' class="fas fa-wrench" style='font-size: x-large;'></i>
                  <i v-else class="fa-solid fa-check" style='font-size: x-large;'></i>
                </template>

                <!-- Personalizar header -->
                <template #header="{ item }">
                  <h4 class="text-primary">Estado: {{ item.estado == 0 ? 'Abierto' : item.estado == 1 ? 'Proceso' :
                    'Finalizado' }}</h4>
                </template>

                <!-- Personalizar contenido -->
                <template #content="{ item }">
                  <div class="my-2">
                    <p>Descipcion: {{ item.estado == 0 ? mantenimiento.descripcion : item.descripcion }}</p>
                    <div class="mt-2">
                      Zonas:
                      <span v-for='(zona, index) in zonas' :key='index' class="badge bg-primary mr-1">
                        {{ zona }}
                      </span>
                    </div>
                    <figure>
                      <h3 v-if='item.imagenes.length != 0'>Imagenes</h3>
                      <a v-for='image in item.imagenes' :key='image.id' :href='image.imageUrl' target='_blank'>
                        <img :src="image.imageUrl" alt="Imagen de mantenimiento" class="img-fluid mt-2 mr-3"
                          style='width: 100px; height: 100px; object-fit: cover; border-radius: 20%;'>
                      </a>
                    </figure>
                  </div>
                  <p>Fecha: {{ item.fecha.split("T")[0] }}</p>
                </template>
              </TimeLine>
              <div class='d-flex justify-content-end mb-3'>
                <vs-button v-if='mantenimiento.estado !== "Finalizado"' icon
                  @click="() => this.$bvModal.show('modal-evidencias')">
                  <i class="fas fa-plus-circle mr-2"></i>
                  Agregar Proceso
                </vs-button>
              </div>
            </div>
            <div class='col-12 col-lg-6'>
              <div class="bg-body p-3 border"
                style="border-radius: 1rem; box-shadow: 0px 1px 13px 5px rgb(0 0 0 / 0.1)">
                <div class="d-flex align-items-center" style="gap: 2rem">
                  <vs-avatar circle size="70">
                    <img v-if="empresa.urlLogo !== null" :src="empresa.urlLogo" alt="Logo de la empresa" />
                    <ion-icon style="font-size: 3rem" v-else name="business-outline"></ion-icon>
                  </vs-avatar>
                  <div>
                    <h4 class="card-title m-0">{{ empresa.nombre }}</h4>
                    <p class="m-0">{{ empresa.direccionDelDomicilioPrincipal }}</p>
                    <p class="m-0">{{ empresa.correo }}</p>
                  </div>
                </div>
                <hr style="height: 0.8px; background: var(--dark)" class="w-100 mb-3" />
                <div class='row'>
                  <h4 class="card-title m-0 col-12 col-md-6">Solicitante: <b>{{ mantenimiento.solicitante.nombre }}</b>
                  </h4>
                  <h4 class="card-title mt-2 mt-md-0 col-12 col-md-6">Tecnico: <b>{{ mantenimiento.tecnico.nombre }}</b>
                  </h4>
                  <p class="card-title m-0 col-12 col-md-6">Lugar: <b>{{ mantenimiento.lugar.nombre }}</b>
                  </p>
                  <p class="card-title mt-2 mt-md-0 col-12 col-md-6">Zonas: <b>{{ mantenimiento.Zona }}</b></p>
                  <p class="card-title m-0 col-12 col-md-6">Sucursal: <b>{{ mantenimiento.sucursal.descripcion }}</b>
                  </p>
                  <p class="card-title mt-2 mt-md-0 col-12 col-md-6">Departamento: <b>{{
                    mantenimiento.departamento.descripcion
                      }}</b>
                  </p>
                  <p class="card-title m-0 col-12 col-md-6">Area: <b>{{ mantenimiento.area.descripcion }}</b>
                  </p>
                  <p class="card-title mt-2 mt-md-0 col-12 col-md-6">Cargo: <b>{{ mantenimiento.cargo.nombre }}</b></p>
                  <p class="card-title m-0 col-12 col-md-6">Estado: <b>{{ mantenimiento.estado }}</b>
                  </p>
                  <p class="card-title mt-2 mt-md-0 col-12 col-md-6">Prioridad: <b>{{ mantenimiento.prioridad }}</b></p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex pt-4 px-5 justify-content-end">
            <vs-button size="large" dark shadow @click="$router.push('/helex/Erp/Mantenimientos')">Regresar</vs-button>
            <!-- <vs-button size="large"
              @click="$router.push(`/helex/Erp/DetalleMantenimiento/${this.$route.params.id}`)">Editar</vs-button> -->
          </div>
        </card>
        <b-modal id="modal-evidencias" size="lg" hide-footer>
          <h2 class="font-weight-bold">Agregar Proceso</h2>
          <form @submit.prevent="addProceso">
            <div class="mb-3 w-50">
              <label for="prioridad" class="form-label">Estado</label>
              <b-form-select id="prioridad" :options="ListaEstado" required v-model="nuevoProceso.Estado"
                value-field="id" text-field="nombre"><template #first>
                  <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                    --</b-form-select-option>
                </template></b-form-select>
            </div>
            <div class="form-group">
              <label for="descripcion">Descripcion</label>
              <textarea class="form-control" id="descripcion" rows="3" v-model="nuevoProceso.Descripcion"></textarea>
            </div>

            <div class="form-group">
              <label for="imagenes">Imagenes</label>
              <input type="file" class="form-control-file" id="imagenes" @change="onFileChange" multiple>
            </div>
            <div class="d-flex justify-content-end">
              <vs-button type="submit">Agregar</vs-button>
            </div>
          </form>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import TimeLine from '../../../../components/Timeline/TimeLine.vue';
  import { core } from "../../../../config/pluginInit";
  import Swal from 'sweetalert2';
  import _ from "lodash";

  export default {
    components: {
      TimeLine
    },
    data: () => ({
      timelineItems: null,
      mantenimiento: {},
      empresa: {},
      usuario: null,
      ListaEstado: [
        { id: 1, nombre: "Proceso" },
        { id: 2, nombre: "Finalizado" }
      ],
      manteniminetodetalles: null,
      zonas: [],
      nuevoProceso: {
        Descripcion: '',
        Estado: 0,
      },
      filess: [],
    }),
    async mounted() {
      const persona = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.usuario = persona;
      this.empresa = persona.empresa;
      core.index();
      await this.getData();
    },
    methods: {
      onFileChange(e) {
        this.filess = Array.from(e.target.files);
      },
      quitarTildesYEspacios(input) {
        let sinTildes = _.deburr(input);
        let sinEspacios = sinTildes.replace(/\s+/g, "");
        return sinEspacios;
      },
      async addProceso() {
        this.$isLoading(true);
        let formData = {
          Descripcion: this.nuevoProceso.Descripcion,
          Estado: this.nuevoProceso.Estado,
          MantenimientoId: this.$route.params.id,
          Fecha: new Date()
        }
        let resMantenimientoDetalle = await this.$store.dispatch("hl_post", {
          path: "MantenimientoDetalles/GuardarMantenimientoDetalles",
          data: formData,
        })

        if (resMantenimientoDetalle.date === null) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Error al guardar el proceso",
            confirmButtonText: "Aceptar",
          });
          return;
        }

        await this.$store.dispatch("hl_post", {
          path: `Mantenimiento/CambiarEstadoMantenimiento/${this.$route.params.id}`,
          data: this.nuevoProceso.Estado
        })


        
        let urlImages = [];
        
        if (this.filess.length == 0) {
          this.$isLoading(false);
          this.getData();
          Swal.fire({
            icon: "success",
            title: "Proceso guardado correctamente",
            confirmButtonText: "Aceptar",
          });
          this.nuevoProceso = {
            Descripcion: '',
            Estado: 0,
          };
          this.$bvModal.hide('modal-evidencias');
        }
        
        for (let i = 0; i < this.filess.length; i++) {
          let resurl = await this.onUpload(this.usuario.empresa.id, this.filess[i], this.filess[i].type.split("/")[1]);
          urlImages.push(resurl);
        }

        for (let i = 0; i < urlImages.length; i++) {
          let data = {
            MantenimientoDetallesId: resMantenimientoDetalle.data,
            ImageUrl: urlImages[i].url,
            NameUrl: urlImages[i].name,
          };
          await this.$store.dispatch("hl_post", {
            path: "MantenimientoDetalles/GuardarMantenimientoImagenes",
            data: data,
          })
        }
        
        this.$isLoading(false);
        this.getData();
        Swal.fire({
          icon: "success",
          title: "Proceso guardado correctamente",
          confirmButtonText: "Aceptar",
        });
        this.nuevoProceso = {
          Descripcion: '',
          Estado: 0,
        };
        this.$bvModal.hide('modal-evidencias');
      },
      async getData() {
        let resMantenimiento = await this.$store.dispatch("hl_get", {
          path: `Mantenimiento/GetMantenimientoId/${this.$route.params.id}`,
        });
        let resZonaId = await this.$store.dispatch('hl_get', {
          path: `Mantenimiento/GetMantenimientosZonaId/${resMantenimiento.data.id}`
        });
        let resMantenimientodetalles = await this.$store.dispatch('hl_get', {
          path: `MantenimientoDetalles/ObtenerMantenimientoDetalles/${resMantenimiento.data.id}`
        });
        this.timelineItems = resMantenimientodetalles.data;
        resMantenimiento.data.Zona = resZonaId.data.map((zona) => zona.nombre).join(', ');
        resMantenimiento.data.prioridad === 1 ? resMantenimiento.data.prioridad = 'Baja' : resMantenimiento.data.prioridad === 2 ? resMantenimiento.data.prioridad = 'Media' : resMantenimiento.data.prioridad = 'Alta';
        resMantenimiento.data.estado === 0 ? resMantenimiento.data.estado = 'Abierto' : resMantenimiento.data.estado === 1 ? resMantenimiento.data.estado = 'Proceso' : resMantenimiento.data.estado = 'Finalizado';

        this.zonas = resZonaId.data.map((zona) => zona.nombre);

        this.mantenimiento = resMantenimiento.data;
      },
      async onUpload(empresaId, file, typeFile) {
        try {
          let nameFileT = this.$moment.tz(new Date(), 'America/Bogota').format('DDMMYYYYHHmmss') + Math.floor(Math.random() * (1 - 99999 + 1)) + 1;
          let dataGet;

          try {
            const responseGet = await this.$store.getters.fetchGet({
              path: "MantenimientoDetalles/ObtenerMantenimientoImagenes/" + empresaId,
            });
            dataGet = await responseGet.json();
          } catch {
            nameFileT = this.$moment.tz(new Date(), 'America/Bogota').format('DDMMYYYYHHmmss') + Math.floor(Math.random() * (1 - 99999 + 1)) + 1;
          }

          const newPath = this.quitarTildesYEspacios("empresa/" + this.usuario.empresa.id + "/mantenimiento/" + "Estado/0");

          const lastDirectory = newPath.substring(newPath.lastIndexOf("/") + 1);

          const filename = `${lastDirectory}_${nameFileT}.${typeFile}`;
          const urlImg = newPath.split("/").join("|");

          const fileModel = {
            filename: filename,
            file: file,
            url: ".|" + urlImg,
          };

          if (dataGet.data !== null) {
            if (dataGet.data.filter((item) => item.nameurl === filename).length !== 0) {
              Swal.fire({
                icon: "error",
                title: "Error!",
                text: `Ya existe un archivo con el nombre ${filename}`,
                confirmButtonText: "Aceptar",
              });
              return;
            }
          }

          let isSave = await this.$store.dispatch("onDropFile", fileModel);

          if (!isSave) {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Error al subir el archivo",
              confirmButtonText: "Aceptar",
            });
            return false;
          }

          return { url: "https://www.helexiumfiles.com/" + newPath + "/" + filename, name: filename };

        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error.message,
            confirmButtonText: "Aceptar",
          });
        } finally {
          this.loader = false;
        }
      }
    }
  };
</script>

<style scoped>
  .active {
    border-color: #df122e;
  }
</style>
